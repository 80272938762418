import Modal from '@src/components/layouts/Modal';
import PopupProvider, { usePopup } from '@src/contexts/PopupContext';
import { persistor } from '@src/store';
import { GlobalStyles, soritownTheme } from '@src/theme/default';
import '@styles/globals.css';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider as StyledThemeProvider, useTheme } from 'styled-components';

declare global {
	interface Window {
		Kakao: any;
        naver: any;
	}
};

function MyApp({ Component, pageProps }: AppProps)
{
    const theme = useTheme();
    
    return (
        <>
            <script src="https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js" charSet="utf-8"></script>
            <StyledThemeProvider theme={ soritownTheme }>
                <PopupProvider>
                    <PersistGate loading={ null } persistor={ persistor }>
                        <div className="app-container">
                            <GlobalStyles theme={ theme } />
                            <Component { ...pageProps } />
                            <Modal />
                        </div>
                    </PersistGate>
                </PopupProvider>
            </StyledThemeProvider>
        </>
    );
}

export default MyApp;

import { FlattenSimpleInterpolation } from 'styled-components';
import { css, CSSProp } from 'styled-components';

// 기기를 세 종류로 나눌 때 쓰는 파일
export type breakPointLabel = 'tablet' | 'mobile';
export enum breakPointEnum {
	tablet,
	mobile,
}

export const breakPoint = {
	labtop: 1439,
	tablet: 1023,
	mobile: 767,
};

export type breakPointType = {
	labtop: (strings: TemplateStringsArray, ...expr: any[]) => CSSProp;
	tablet: (strings: TemplateStringsArray, ...expr: any[]) => CSSProp;
	mobile: (strings: TemplateStringsArray, ...expr: any[]) => CSSProp;
};

export default Object.keys(breakPoint).reduce((acc: breakPointType, label) => {
	let label_: breakPointLabel = ['labtop', 'tablet', 'mobile'].find(e => {
		if (e === label)
			return e;
	}) as breakPointLabel;

	(acc as { [index: string]: (strings: TemplateStringsArray, ...expr: any[]) => FlattenSimpleInterpolation })[label] = (strings: TemplateStringsArray, ...expr: any[]) => css`
		@media (max-width: ${breakPoint[label_]}px) {
			${css(strings, ...expr)};
		}
	`;
	
	return acc;
}, {} as breakPointType); 

import { usePopup } from "@src/contexts/PopupContext";
import { ThemeType } from "@src/types/theme";
import Image from "next/image";
import styled from "styled-components";

export type ModalProps = {

}

export default function Modal(props: ModalProps)
{
    const popup = usePopup();

    if (!popup.isPopupOpen)
        return null;

    return(
        <Wrapper>
            { popup.modalContent }
            <Border />
            <CloseBtn onClick={ popup.closePopup }>
                <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7036 0C5.23989 0 0 5.23989 0 11.7036C0 18.1673 5.23989 23.4072 11.7036 23.4072C18.1673 23.4072 23.4072 18.1673 23.4072 11.7036C23.4072 5.23989 18.1673 0 11.7036 0ZM8.53895 16.6123C8.24762 16.9036 7.77528 16.9036 7.48396 16.6123C7.19263 16.3209 7.19263 15.8486 7.48396 15.5573L10.649 12.3923L7.48367 9.22697C7.19234 8.93564 7.19234 8.4633 7.48367 8.17197C7.775 7.88065 8.24734 7.88065 8.53866 8.17197L11.704 11.3373L14.8689 8.17231C15.1602 7.88099 15.6326 7.88099 15.9239 8.17231C16.2152 8.46364 16.2152 8.93598 15.9239 9.22731L12.7589 12.3923L15.9236 15.5569C16.2149 15.8482 16.2149 16.3206 15.9236 16.6119C15.6323 16.9032 15.1599 16.9032 14.8686 16.6119L11.704 13.4472L8.53895 16.6123Z" fill="white"/>
                </svg>
            </CloseBtn>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 500px;
    height: 500px;
    position: absolute;
    top: 55vh;
    left: 50vw;
    z-index: 2;
    transform: translate(-50%, -50%);
    border-radius: 1vw;
    filter: drop-shadow(0 0 0.7vw rgba(0, 0, 0, 0.40)); 

    ${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
        @media (orientation: landscape)
        {
            width: 80vh;
            height: 80vh;
        };

        @media (orientation: portrait)
        {
            width: 80vw;
            height: 80vw;
        };
    `};
`;

const Border = styled.div`
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    border-radius: 1vw;
    border: 0.5vw solid #FFF; 

    ${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
        @media (orientation: landscape)
        {
            width: 80vh;
            height: 80vh;
        };

        @media (orientation: portrait)
        {
            width: 80vw;
            height: 80vw;
        };
    `};
`;


const CloseBtn = styled.button`
    position: absolute;
    top: -50px;
    right: -5px;
    z-index: 3;
    filter: drop-shadow(0 0 0.7vw rgba(0, 0, 0, 0.5)); 
    cursor: url("/source/cursor_pointer.png"), pointer;
`;

import { createWrapper, HYDRATE, MakeStore, Context } from "next-redux-wrapper";
import { configureStore, combineReducers, EnhancedStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";
// import userReducer from "./user";
// import currencyReducer from "./currency";
import { PERSIST, persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage
}

const rootReducer = combineReducers({
    // user: userReducer,
    // media: mediaReducer,
    // currency: currencyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

const middleware = (getDefaultMiddleware: any) => {
    const middlewareArray = getDefaultMiddleware({
        serializableCheck: {
            // 필요한 경우, 직렬화할 수 없는 필드 제외하기
            ignoredActions: [PERSIST],
            // 필요한 경우, 특정 필드에서 직렬화할 수 없는 값을 제외하기
            ignoredPaths: ["user.password"],
        },
    });

    if (process.env.NODE_ENV === "development") {
        // middlewareArray.push(logger);
    }

    return middlewareArray;
};

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NEXT_PUBLIC_NODE_ENV !== "production",
    middleware
});

const setupStore = (context: any): EnhancedStore => store;
const makeStore: MakeStore<any> = (context: any) => setupStore(context);

export const persistor = persistStore(store);
export const wrapper = createWrapper(makeStore);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
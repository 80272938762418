import { createGlobalStyle } from 'styled-components';
import updatedMedia from './media';
import { ThemeType } from '@src/types/theme';

const soritownTheme: ThemeType = {
	breakPoint: {
		...updatedMedia,
	},
    themeColor: {
        backgroundSky1: "#00B0F0",    // #00B0F0
        backgroundSky2: "#6EDCFF ",   // #6EDCFF
        backgroundSky3: "#98D3F4 ",   // #98D3F4
        backgroundSky4: "#63BDEF",    // #63BDEF
        backgroundRed1: "#F4C2BA",    // #F4C2BA
        backgroundRed2: "#E76B7A",    // #E76B7A
        backgroundRed3: "#E35364",    // #E35364
        Textyellow: "#FFE85D",    // #FFE85D
        text: "black",
    }
}

const GlobalStyles = createGlobalStyle`

    body {
        color: ${({ theme }: { theme: ThemeType }) => theme.themeColor.text};
        font-family: BM JUA_TTF, 'Encode Sans Expanded', Tahoma, Helvetica, Arial, Roboto, sans-serif;
        transition: all 0.50s linear;
    };

    p {
        margin: 0;
    };
`

export { soritownTheme, GlobalStyles };
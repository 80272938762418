import Modal from "@src/components/layouts/Modal";
import React, { useContext } from "react";
import { useState } from "react";

const PopupContext = React.createContext({
    isPopupOpen: false,
    openPopup: (content: React.ReactNode) => {},
    closePopup: () => {},
    modalContent: new Object as React.ReactNode,
});

export function usePopup() {
    return useContext(PopupContext);
}

export default function PopupProvider({ children }: { children: React.ReactNode })
{
    const [ isPopupOpen, setIsPopupOpen ] = useState(false);
    const [ modalContent, setModalContent ] = useState<React.ReactNode>(null);

    const openPopup = (content: React.ReactNode) => {
        setModalContent(content);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setModalContent(null);
    };

    return (
        <PopupContext.Provider value={{ isPopupOpen, openPopup, closePopup, modalContent }}>
            { children }
        </PopupContext.Provider>
    );
}